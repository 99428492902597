export default {
    namespaced: true,
    state: {
        props: null
    },
    actions: {
        notifiedOK({commit}, message) {
            commit('notifiedSuccess', message)
        },
        notifiedTxOK({commit}, payload) {
            commit('notifiedSuccess', payload)
        },
        notifiedOKByCode({commit}, code) {
            commit('notifiedSuccess', getSuccessMessage(code ? code : 0))
        },
        notifiedError({commit}, message) {
            commit('notifiedFailure', message)
        },
        notifiedErrorByCode({commit}, code) {
            commit('notifiedFailure', getErrorMessage(code ? code : 0))
        },
        notifiedWarning({commit}, code) {
            commit('notifiedWarn', getErrorMessage(code))
        },
        notifiedInfo({commit}, message) {
            commit('notifiedInf', message)
        }
    },
    mutations: {
        notifiedSuccess(state, payload) {
            let response = {
                title: 'Excelente',
                text: payload.message ? payload.message: payload,
                created: null,
                variant: 'success',
                hash: payload.hash ? payload.hash: null
            }
            state.props = response
        },
        notifiedFailure(state, message) {
            let response = {
                title: 'Error',
                text: message,
                created: null,
                variant: 'danger'
            }
            state.props = response
        },
        notifiedWarn(state, message) {
            let response = {
                title: 'Atención',
                text: message,
                created: null,
                variant: 'warning'
            }
            state.props = response
        },
        notifiedInf(state, message) {
            let response = {
                title: 'Info',
                text: message,
                created: null,
                variant: 'info'
            }
            state.props = response
        }

    }
}


export function getSuccessMessage(code) {
    let message = '';
    switch (code) {
        case 2000:
            message = "Todo OK."
            break
        default:
            message = ''
            break
    }
    return message
}

export function getErrorMessage(code) {
    let message = '';
    return message;
}

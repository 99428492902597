<template>
  <b-toast :variant="notification.props.variant" id="app-toast" v-if="notification.props">
    <template #toast-title>
      <div class="d-flex flex-grow-1 align-items-center mr-1">
        <b-img
            :src="appLogoImage"
            class="mr-1"
            width="25"
            alt="CVR Logo"
        />
        <h4 class="mr-auto"><strong>{{notification.props.title}}</strong></h4>
        <small class="text-muted" v-if="notification.props.created">{{notification.props.created}}</small>
      </div>
    </template>
    <h4 class="text-black">
      {{notification.props.text}}
      <span v-if="notification.props.hash">
        <b-link :href="`https://bscscan.com/tx/${notification.props.hash}`" target="_blank" class="nav-link">
        <feather-icon icon="EyeIcon" size="20"/> Ver Transacción
      </b-link>
      </span>
    </h4>

  </b-toast>
</template>

<script>
import {mapState} from "vuex";
import {BToast, BImg, BLink} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
export default {
name: "AppToast",
  components: {
    BToast, BImg, BLink
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      notification: state => state.notification
    }),
  },
  watch:{
  'notification.props'(val){
    if(val){
      setTimeout(() => {
        this.$bvToast.show('app-toast')
      }, 500);
    }
  }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  }
}
</script>

<style scoped>

</style>